<template>
  <div class="f2g-color-picker">
    <InputColorPicker
      ref="inputColorPicker"
      v-model="color"
      class="f2g-color-picker-input"
      :class="{ 'f2g-color-picker-input-disabled': disabled }"
      :disabled="disabled"
    />
  </div>
</template>

<script>
export default {
  name: "F2gColorPicker",
  props: {
    clearData: { required: false, type: Boolean, default: false },
    colorProp: { required: false, type: String },
    disabled: { required: false, type: Boolean, default: false },
  },
  data() {
    return {
      color: "",
    };
  },
  components: {
    InputColorPicker: () => import("vue-native-color-picker"),
  },
  beforeMount() {
    this.color = this.colorProp;
  },
  watch: {
    color() {
      this.$emit("colorPicked", this.color);
      if (this.$refs.inputColorPicker)
        this.$refs.inputColorPicker.styleObject.backgroundColor = this.color;
    },
    clearData() {
      if (this.clearData) this.color = this.colorProp;
    },
    disabled() {
      if (this.disabled) {
        this.color = this.colorProp;
        this.$refs.inputColorPicker.styleObject.backgroundColor = this.color;
      }
    },
    colorProp: {
      immediate: true,
      handler() {
        if (this.colorProp) this.color = this.colorProp;
      },
    },
  },
  computed: {
    background() {
      return { "background-color": `${this.color} !important` };
    },
  },
};
</script>

<style lang="scss" scoped>
.f2g-color-picker-input {
  border: 1px solid lightgrey !important;
  border-radius: 13px;
  width: 40px;
}
.f2g-color-picker-input-disabled {
  cursor: default !important;
}
</style>